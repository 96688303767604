* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

body {
    background-color: #F5F7FA;
}

.upload-container {
    text-align: center;
    margin-top: 0;
    width: 100%;
}

.drop-area {
    background-color: #3F51B5;
    color: white;
    padding: 20px 80px;
    margin: 20px 0;
    border-radius: 10px;
    border: 2px dashed #ffffff;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.drop-area input {
    display: none;
}

.drop-area-label {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    font-size: 40px;
    margin-bottom: 10px;
}

.file-chooser {
    margin-top: 10px;
}

.choose-file-button {
    background-color: white;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:disabled {
    background-color: #b3d7ff;
}

/* button:hover:enabled {
    background-color: #0056b3;
} */

/* HTML: <div class="loader"></div> */
.loader {
    width: 150px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: #25b09b;
    animation: l15 1s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
}

.loader::after {
    margin: 8px;
    animation-duration: 3s;
}

@keyframes l15 {
    100% {
        transform: rotate(1turn)
    }
}


ol li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 23px;
    color: #505050;
    counter-increment: my-awesome-counter;
    max-width: calc(33% - 40px);
    vertical-align: middle;
    text-align: left;
    margin-right: 24px;
}

ol li span {
    display: inline-block;
    width: calc(100% - 50px);
    vertical-align: middle;
    padding-left: 15px;
}

ol li:before {
    content: counter(my-awesome-counter);
    line-height: 27px;
    color: #e74c3c;
    font-weight: 700;
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
    border-radius: 30px;
    height: 29px;
    width: 29px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
}

ol {
    margin-top: 33px;
    list-style: none;
    padding-left: 0;
    counter-reset: my-awesome-counter;
    text-align: center;
}

.container {
    max-width: 1224px;
    margin: auto;
    position: relative;
}


.upload-container {
    margin: 0;
    padding: 0px;
}

.drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
}

.drop-area-label {
    display: block;
    margin-top: 10px;
}

.choose-file-button {
    cursor: pointer;
    background-color: #dc2a4c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
}

.pdf-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
    border-radius: 5px;
}

.pdf-placeholder-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    background-color: #dc2a4c1c;
    justify-content: center;
    height: 100%;
    overflow: auto;
    border-radius: 5px;
}

.pdf-placeholder-panel img {
    width: 150px;
    opacity: 0.5;
}

.pdf-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 200px;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pdf-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.pdf-page img {
    max-width: 90%;
    max-height: 80vh;
    border: 9px solid #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0px #999;
}

.pdf-placeholder {
    margin-top: 35px;
    width: 70%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(102, 102, 102) 0px 0px 12px 0px;
    border-radius: 3px;
}

.resultCard {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    justify-content: center;
}

.resultCard>div {
    flex: 0 0 auto;
    width: 25%;
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    position: relative;
}

.resultCard>div>svg {
    position: absolute;
    z-index: 99;
    right: 26px;
    top: 12px;
    color: #000
}

.resultCard>div>div {
    border-radius: .8rem !important;
    text-align: center !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    flex: 1 1 auto;
    padding: 1rem;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
}

.resultBlock {
    position: relative;
}

.copyIcon {
    position: absolute;
    top: 10px;
    /* Adjust as needed */
    right: 10px;
    /* Adjust as needed */
    z-index: 100;
    /* Ensure the icon is above other elements */
    cursor: pointer;
    /* Indicate that the icon is clickable */
}

.result {
    color: #212529 !important;
    margin-top: 1rem !important;
    font-size: calc(1.3rem + .6vw);
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: .5rem;
}

.numberList {
    text-align: left;
    margin: 0;
}

.numberList li {
    display: block;
    max-width: 100%;
}

.bottom-ul:after {
    content: ' ';
    clear: both;
    width: 3em;
    display: block;
    margin: 5px 0 15px;
    border-bottom: 3px solid #f42534;
}

.overlay {
    position: fixed;
    /* Use fixed to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    /* Light opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's on top */
}

.progress-container {
    width: 50%;
    /* Adjust the width of the progress bar */
    text-align: center;
}

.progress-percentage {
    margin-top: 10px;
    /* Space between the progress bar and percentage text */
    font-weight: bold;
    /* Make the percentage text bold */
}

.custom-carousel .carousel {
    overflow: initial !important;
}

.custom-carousel .carousel .control-dots {
    position: absolute;
    bottom: -40px;
    /* Adjust as needed */
    width: 100%;
    display: flex;
    justify-content: center;
}

.custom-carousel .carousel .control-dots .dot {
    background-color: #ffffff;
    /* Customize dot color */
    opacity: 0.8;
}